import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import React, { useState, useContext, useEffect } from 'react';
import { Button, TextInput } from 'shared-library';
import { formatCurrency } from 'packages/formatters';
import styled from 'styled-components';
import { StudyContext, PiiContext, BookingContext } from 'packages/booking-contexts';
import { Column, Row } from '../Layout';
import { StripeElementOnly } from './PaymentTextInputs';
import { createToken } from './stripeFunctions';
import { PaymentContext } from '../PaymentContext';
import { fetchAppsResource } from 'packages/apis';

const PaymentButton = styled(Button)`
  display: flex;
  margin: auto;
`;

export const AffirmCheckout = ({ openAlert, onChange }) => {
  const elements = useElements();
  const stripe = useStripe();
  const { piiContext } = useContext(PiiContext);
  const { selectedLocation, studyId, skuID } = useContext(StudyContext);
  const { load, total, recordPayment } = useContext(PaymentContext);

  const { firstname, lastname } = piiContext;
  const autoPopName = `${firstname} ${lastname}`;
  const [name, setName] = useState(autoPopName);
  const [postalCode, setPostalCode] = useState('123456');
  const { isTemporaryHold, setIsTemporaryHold } = useContext(BookingContext);

  const postPayment = async (token) => {
    if (!studyId || studyId === 'None') {
      return openAlert(
        'Please complete location, time, and patient details before processing payment',
        ' ',
      );
    }
    const { id, card } = token;
    const { transaction: paymentTransactionResponse, code } = await recordPayment(
      {
        details: {
          stripeToken: id,
          cardType: card && card.brand,
          cardLastFour: card && card.last4,
          paymentMethod: 'affirm',
        },
        skuID,
        locationID: selectedLocation.locationID,
        paymentType: 'stripe',
        amount: total,
      },
      onChange,
    ).catch((e) => {
      openAlert('Payment Processing Error', e.response.data.toString());
    });
    if (code === 200) {
      await load();

      if (isTemporaryHold) {
        await fetchAppsResource('/calendar/convert-temporary-hold', 'POST', { studyId });
      }
      
      openAlert(
        'Payment Successful',
        `Successfully charged ${formatCurrency(total, selectedLocation?.currency?.currency)} to ${
          card.brand
        } card ending in ${card.last4}.`,
      );
    } else {
      openAlert('Payment Processing Error', paymentTransactionResponse.memo);
    }
  };

  const processPayment = async () => {
    const { error, token } = await createToken({ name, postalCode }, elements, stripe);
    if (error) {
      openAlert('Card Error', error);
    }
    if (token) {
      await postPayment(token);
    }
  };

  return (
    <>
      <Row>
        <Column>
          <TextInput
            id="name"
            label="Cardholder Name*"
            placeholder="Jenny Rosen"
            value={name}
            key="name"
            required
            onChange={setName}
          />
        </Column>
        <Column>
          <TextInput
            id="postalCode"
            label="Zip/Postal Code*"
            placeholder="123456"
            value={postalCode}
            key="postalCode"
            required
            onChange={setPostalCode}
          />
        </Column>
        <Column />
      </Row>
      <StripeElementOnly />
      <Row>
        <Column />
        <Column>
          <PaymentButton
            onClick={processPayment}
            disabled={!(name && postalCode)}
            variant="primary"
            size="small"
          >
            Process Payment
          </PaymentButton>
        </Column>
        <Column />
      </Row>
    </>
  );
};

const AffirmCheckoutWithElements = ({ openAlert, onChange }) => {
  const [stripePromise, setStripePromise] = useState(null);
  const { stripeTokens } = useContext(BookingContext);
  const { selectedLocation } = useContext(StudyContext);

  useEffect(() => {
    const getSelectedLocationInfo = async () => {
      const stripeToken = stripeTokens[selectedLocation?.locationID];
      if (stripeToken && !stripePromise) {
        const loadedStripe = await loadStripe(stripeToken);
        setStripePromise(loadedStripe);
      }
    };
    getSelectedLocationInfo();
  }, [stripeTokens]);

  return (
    <Elements stripe={stripePromise}>
      <AffirmCheckout onChange={onChange} openAlert={openAlert} />
    </Elements>
  );
};

export default AffirmCheckoutWithElements;
